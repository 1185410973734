<template>
    <div class="app-container stadium-reservation">
        <div class="filter-line">
            <span>
                <el-button type="primary" icon="el-icon-plus" @click="handleFix">预定场地</el-button>
                <el-button @click="exportHandler">{{ this.$t("commons.export") }}</el-button>
                <el-button icon="el-icon-refresh-right" @click="getTableList">{{ this.$t("commons.refresh") }}</el-button>
            </span>
            <div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show"
                :class="dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'">
                <i class="iconfont icon-loudoutu"></i>筛选
            </div>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler">
                <template v-slot:siteTimeListStr="row">
                    <span v-if="row.datas.siteTimeListStr">
                        <span class="single-time-span">
                            <span>
                                {{ row.datas.siteTimeListStr }}
                            </span>
                        </span>
                    </span>
                </template>
                <template v-slot:destinedStatusStr="row">
                    <span v-if="row.datas.destinedStatusStr">
                        <span class="single-time-span">
                            <span>
                                {{ row.datas.destinedStatusStr }}
                            </span>
                        </span>
                    </span>
                </template>
                <template v-slot:destinedTime="row">
                    <span v-if="row.datas.destinedDate">
                        <span class="single-time-span">
                            <span>
                                {{ row.datas.destinedDate | dateFormat("YYYY-MM-DD") }}
                            </span>
                        </span>
                    </span>
                </template>
                <template v-slot:siteListStr="row">
                    <span v-if="row.datas.siteListStr">
                        <span class="single-time-span">
                            <span>
                                {{ row.datas.siteListStr }}
                            </span>
                        </span>
                    </span>
                </template>
                <template v-slot:invoiceBillNoSlot="row">
                    <span :class="row.datas && 'link-span'" @click="jumpToInvoiceList(row.datas.invoiceBillNo)">
                        {{ row.datas.invoiceBillNo || "-" }}
                    </span>
                </template>
            </finalTable>
        </div>
        <!-- 预定场地or详情 弹窗 -->
        <el-dialog :visible.sync="dialogVisible" width="728px" :before-close="handleClose" :close-on-click-modal="false"
            class="Popup">
            <template slot="title">
                <span v-if="suggestionObj.dialogVisible">预定详情</span>
                <span v-else>体育场预定</span>
            </template>
            <template v-if="suggestionObj.dialogVisible">
                <detailed :detailsObj="detailsObj" @close="successAndClose"></detailed>
            </template>
            <template v-else>
                <reservation ref="reservationRef"></reservation>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" @click="addreservation">确认预约</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 取消预定 弹窗 -->
        <el-dialog title="取消预定" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose" class="Dialog"
            :close-on-click-modal="false">
            <el-form :model="ruleFormDialog" :rules="rulesDialog" ref="ruleFormDialog" label-width="100px"
                class="demo-ruleForm" label-position="top">
                <el-form-item label="请填写取消原因,取消原因将会发送给用户" prop="cancelReason">
                    <el-input type="textarea" :rows="4" placeholder="" v-model="ruleFormDialog.cancelReason" maxlength="20">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-checkbox v-model="cancelchecked" class="dialog-checkbox" v-show="cancelShow">费用退回</el-checkbox>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">关 闭</el-button>
                <el-button type="primary" @click="getRemoveOrder">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import reservation from "./reservation"
import detailed from "./detailed"
import {
    getScheduleList,
    editInvoiceStatus,
    addPredetermine,
    getComplaintAdviceDetail,
    paymentListExport,
    removeOrder,
    getSerachList,
    getSerachTimeList
} from "@/api/ruge/gsPark/customerService/stadium";
import { dateFormat } from "@/filters/index";
export default {
    name: "stadiumComponent",
    components: {
        finalTable,
        reservation,
        detailed
    },
    data() {
        return {
            ruleFormDialog: {
                cancelReason: '',
            },
            rulesDialog: {
                cancelReason: [
                    { required: true, message: '请输入取消原因', trigger: 'blur' },
                    { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                ]
            },
            detailsObj: {},
            dialogVisible: false,
            dialogVisible1: false,
            cancelReasonId: '',
            cancelShow: false,
            cancelchecked: false,
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "destinedDate", label: "预定日期", width: "" },
                    // { prop: "siteTimeListStr", label: "预定时段", width: "240" },
                    { prop: "destinedSlot", label: "预定时段", width: "240" },
                    { prop: "goodsName", label: "场地名称", width: "" },
                    { prop: "totalMoney", label: "费用", width: "" },
                    // { prop: "destinedStatusStr", label: "状态", width: "" },
                    { prop: "userName", label: "预约人", width: "" },
                    { prop: "userPhone", label: "手机号码", width: "" },
                    { prop: "destinedStatus", label: "状态", width: "" },
                    { prop: "invoiceStatus", label: "是否已开票", width: "" },
                    { prop: "createdName", label: "操作人", width: "" },
                    { prop: "invoiceBillNo", label: "发票编号", width: "" },
                    { prop: "invoiceTime", label: "开票时间", width: "" },
                    { prop: "operation", label: "操作", width: "110" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    // destinedDate: {
                    //     type: "input",
                    //     label: "预定日期",
                    //     value: "",
                    //     actionType: "goSearch",
                    //     placeholder: "请输入预定日期",
                    //     prefixIcon: "el-icon-search",
                    // },
                    destinedSlot: {
                        type: "input",
                        label: "预定时段",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    goodsName: {
                        type: "input",
                        label: "场地名称",
                        value: "",
                        actionType: "goSearch",
                        // optionList: [],
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    totalMoney: {
                        type: "numberInput",
                        label: "费用",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入费用",
                        prefixIcon: "el-icon-search",
                    },
                    userName: {
                        type: "input",
                        label: "预约人",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入",
                        prefixIcon: "el-icon-search",
                    },
                    userPhone: {
                        type: "numberInput",
                        label: "手机号码",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入",
                        prefixIcon: "el-icon-search",
                    },
                    destinedStatus: {
                        type: "selector",
                        label: "状态",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入状态",
                        optionList: [
                            {
                                label: "待支付",
                                value: "0",
                            },
                            {
                                label: "已预定",
                                value: "1",
                            },
                            {
                                label: "已完成",
                                value: "2",
                            },
                            {
                                label: "已取消",
                                value: "3",
                            },
                        ],
                    },
                    invoiceStatus: {
                        type: "selector",
                        label: "是否已开票",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入",
                        optionList: [
                            {
                                label: "是",
                                value: "SUCCESS",
                            },
                            {
                                label: "否",
                                value: "WAIT",
                            },
                        ],
                    },
                    invoicename: {
                        type: "selector",
                        label: "开票名称",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入",
                        optionList: []
                    },
                    invoiceBillNo: {
                        type: "input",
                        label: "发票编号",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入",
                        prefixIcon: "el-icon-search",
                        optionList: []
                    },
                    // invoiceTime: {
                    //     type: "input",
                    //     label: "开票时间",
                    //     value: "",
                    //     actionType: "goSearch",
                    //     prefixIcon: "el-icon-search",
                    //     optionList: []
                    // },
                    createdName: {
                        type: "input",
                        label: "操作人",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    operation: {
                        type: "button",
                        filterCount: "",
                        actionType: "clickEvent",
                        eventName: "showMoreFilters",
                        label: "更多筛选",
                    },
                },
                // 表格内容配置
                detailConfig: {
                    invoiceTime: {
                        type: "dateFormat", // 将格式改成YYYY-MM-DD HH:mm:ss
                    },
                    invoiceBillNo: {
                        type: "slot",
                        slotName: "invoiceBillNoSlot",
                    },
                    destinedDate: {
                        type: "slot",
                        slotName: "destinedTime",
                    },
                    destinedSlot: {
                        type: "slot",
                        slotName: "siteTimeListStr",
                    },
                    destinedStatus: {
                        type: "slot",
                        slotName: "destinedStatusStr",
                    },
                    goodsName: {
                        type: "slot",
                        slotName: "siteListStr",
                    },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "查看",
                            },
                            {
                                actionType: "iconStatus",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-edit",
                                tooltips: "取消预定",
                                showProp: "destinedStatus",
                                showValue: 1,
                            },
                        ],
                    },
                    invoiceStatus: {
                        type: "switch",
                        actionType: "switchEvent",
                    },
                },
                // 高级搜索配置
                advanceFilterConfig: {
                    // siteTimeListStr: {
                    //   type: "dateRange",
                    //   label: "预定时段",
                    //   value: [],
                    // },
                    destinedDate: {
                        type: "dateRange",
                        pickerType: "date",
                        label: "预定日期",
                        value: [],
                    },
                    invoiceTime: {
                        type: "dateRange",
                        pickerType: "date",
                        label: "开票时间",
                        value: [],
                    }
                },
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            // detailDialogShow: false,
            suggestionObj: {
                tableLoading: false,
                dialogVisible: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    startDestinedTime: null,
                    endDestinedTime: null,
                    startInvoiceTime: null,
                    endInvoiceTime: null,
                    destinedSlot: null,
                    goodsIds: null,
                    totalMoney: null,
                    destinedStatus: null,
                    invoiceStatus: null,
                    invoiceBillNo: null,
                }
            },
            // requestParams: {
            //     current: 1,
            //     rowCount: 10,
            //     startDestinedTime: null,
            //     endDestinedTime: null,
            //     startInvoiceTime: null,
            //     endInvoiceTime: null,
            //     destinedSlot: null,
            //     goodsIds: null,
            //     totalMoney: null,
            //     destinedStatus: null,
            //     invoiceStatus: null,
            //     invoiceBillNo: null,
            // }
        }
    },
    created() {
        this.getTableList()
        // this.getSearchList()
    },
    methods: {
        jumpToInvoiceList(invoiceNo) {
            if (!invoiceNo) return;
            sessionStorage.setItem(
                "invoice_list_query_params",
                JSON.stringify({
                    billNo: invoiceNo,
                })
            );
            this.$router.push({
                path: "/gsPark/paymentInvoice/invoiceRecord",
                query: { goBackFlag: true },
            });
        },
        // 初始化数据
        getTableList() {
            this.suggestionObj.tableLoading = true;
            const params = { ...this.suggestionObj.listQuery };
            for (let pn in params) {
                !params[pn] && params[pn] !== 0 && (params[pn] = null);
            }
            getScheduleList(params)
                .then((res) => {
                    console.log(res, '列表数据');
                    res.rows.forEach((item) => {
                        item.invoiceStatus = item.invoiceStatus === "SUCCESS" ? "ENABLE" : "DISABLE";
                    });
                    this.dataset.pageVO.total = res.total;
                    this.dataset.tableData = res.rows;
                    // this.dataset.tableData[0].invoiceBillNo = 'RXFP20230530115011892921'
                    console.log(this.dataset.tableData, 'this.dataset.tableData');
                })
                .finally(() => {
                    this.suggestionObj.tableLoading = false;
                });
        },
        dealSiteListStr(datas) {
            this.suggestionObj.listQuery.goodsIds = datas.params.siteListStr
            if (datas.params.siteListStr && datas.params.siteListStr.length > 0) {
                this.suggestionObj.listQuery.goodsIds = datas.params.siteListStr
                    ? datas.params.siteListStr : null
            } else {
                datas.params.siteListStr = null
            }
        },
        // table组件页面事件配置
        tableEventHandler(datas) {
            console.log("datas", datas);
            if (datas.type === "goSearch") {
                // 更新数据
                // this.suggestionObj.listQuery.goodsIds = datas.params.siteListStr
                this.dealSiteListStr(datas)
                this.dealTime(datas);
                this.invoicingTime(datas);
                this.suggestionObj.listQuery = {
                    ...this.suggestionObj.listQuery,
                    ...datas.params,
                };
                this.suggestionObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                // this.requestParams = { ...this.requestParams, ...datas.params };

                this.getTableList();
            } else if (datas.type === "switchEvent") {
                this.updateEnableStatus(datas.row);
            } else if (datas.type === "iconClick") {
                this.dialogVisible = true
                this.suggestionObj.dialogVisible = true
                getComplaintAdviceDetail({
                    id: datas.row.id
                }).then((res) => {
                    this.detailsObj = res
                })
            } else if (datas.type === "paginationChange") {
                // this.requestParams.current = datas.params.current.page;
                // this.requestParams.rowCount = datas.params.current.limit;
                // 配置分页
                this.suggestionObj.listQuery.current = datas.params.current.page;
                this.suggestionObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            } else if (datas.type === "iconStatus") {
                this.dialogVisible1 = true
                this.cancelReasonId = datas.row.id
                // this.detailConfig.operation.iconList[1].showValue = 0
                console.log(this.dataset.detailConfig.operation.iconList[1].showValue, 'this.detailConfig.operation.iconList');
                // 取消预定 退还费用单选框是否显示
                console.log(datas.row.destinedRoad, 'datas.row.destinedRoad');
                if (datas.row.destinedRoad == 'wx') {
                    this.cancelShow = true
                }
            }
        },
        // 预定日期查询
        dealTime(datas) {
            if (datas.params.destinedDate && datas.params.destinedDate.length > 0) {
                // console.log(datas.params.destinedDate, '22222222222222222');
                this.suggestionObj.listQuery.startDestinedTime = datas.params.destinedDate[0]
                    ? dateFormat(datas.params.destinedDate[0], "YYYY-MM-DD")
                    : null;
                this.suggestionObj.listQuery.endDestinedTime = datas.params.destinedDate[1]
                    ? dateFormat(
                        new Date(datas.params.destinedDate[1]).getTime() +
                        1000 * 60 * 60 * 24 - 1,
                        "YYYY-MM-DD"
                    )
                    : null;
                delete datas.params.destinedDate;
            }
            // else {
            //   this.requestParams.startDate = null;
            //   this.requestParams.endDate = null;
            // }
            return datas;
        },
        // 开票时间查询
        invoicingTime(datas) {
            if (datas.params.invoiceTime && datas.params.invoiceTime.length > 0) {
                // console.log(datas.params.invoiceTime, '22222222222222222');
                this.suggestionObj.listQuery.startInvoiceTime = datas.params.invoiceTime[0]
                    ? dateFormat(datas.params.invoiceTime[0], "YYYY-MM-DD HH:mm:ss")
                    : null;
                this.suggestionObj.listQuery.endInvoiceTime = datas.params.invoiceTime[1]
                    ? dateFormat(
                        new Date(datas.params.invoiceTime[1]).getTime() +
                        1000 * 60 * 60 * 24 - 1,
                        "YYYY-MM-DD HH:mm:ss"
                    )
                    : null;
                delete datas.params.invoiceTime;
            }
            // else {
            //   this.requestParams.startDate = null;
            //   this.requestParams.endDate = null;
            // }
            return datas;
        },

        // 是否已开票 按钮 状态配置
        updateEnableStatus({ id, invoiceStatus }) {
            const emuMap = {
                ENABLE: "SUCCESS",
                DISABLE: "WAIT",
            };
            invoiceStatus;
            editInvoiceStatus({
                id,
                invoiceStatus: emuMap[invoiceStatus],
            }).then((res) => {
                if (res) {
                    this.$message.success("修改状态成功！");
                    this.getTableList();
                }
            });
        },
        // 弹窗关闭配置
        handleClose(done) {
            this.dialogVisible = false;
            this.suggestionObj.dialogVisible = false;
            this.dialogVisible1 = false;
            this.ruleFormDialog.cancelReason = '';
            this.cancelchecked = false;
            this.cancelShow = false;
            if (this.$refs.ruleFormDialog) {
                this.$refs.ruleFormDialog.resetFields();
            }
            // 预定场地 表单重置
            // 判断是哪个弹窗进行关闭,解决没找到refs而控制台报红问题
            if (this.$refs["reservationRef"] && this.$refs["reservationRef"].$refs.ruleForm) {
                this.$refs["reservationRef"].$refs.ruleForm.resetFields();
                this.$refs["reservationRef"].ruleForm.destinedDate = ''
                this.$refs["reservationRef"].ruleForm.monthPayCompany = ''
            }
        },
        successAndClose(flag) {
            this.suggestionObj.dialogVisible = false;
            flag && this.getTableList();
        },
        // 新增场地预定配置
        addreservation() {
            this.$refs["reservationRef"].$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let ruleList = this.$refs["reservationRef"].ruleForm
                    let ruleFormList = {
                        userName: ruleList.userName,
                        userPhone: ruleList.userPhone,
                        destinedDate: ruleList.destinedDate,
                        orderType: 1,
                        siteType: ruleList.orderType,
                        totalMoney: ruleList.totalMoney,
                        monthPayMoney: ruleList.monthPayMoney,
                        monthPayCompany: ruleList.monthPayCompany
                        // goodsList: [
                        //     {
                        //         goodsId: ruleList.siteType[0].goodsId,
                        //         goodsName: ruleList.siteType[0].siteName,
                        //         goodsSlotList: [
                        //             {
                        //                 destinedSlot: ruleList.siteType[1]
                        //             }
                        //         ]
                        //     }
                        // ]
                    }
                    let goodsHeadList = []
                    let goodsList = []
                    // 收集头部
                    ruleList.siteType.map((item) => {
                        goodsHeadList.push(item[0])
                    })
                    // 头部去重
                    let uniqueGoodsHeadList = [...new Set(goodsHeadList)];// 去重
                    // 创建模板
                    uniqueGoodsHeadList.map(item => {
                        goodsList.push({
                            goodsId: item.goodsId,
                            goodsName: item.siteName,
                            goodsSlotList: []
                        })
                    })
                    // 往模板填时间段
                    ruleList.siteType.map((item) => {
                        goodsList.map((j, jIndex) => {
                            if (item[0].goodsId == j.goodsId) {
                                goodsList[jIndex].goodsSlotList.push({ destinedSlot: item[1] })
                            }
                        })
                    })
                    // 赋值ruleFormList.goodsList
                    ruleFormList.goodsList = Object.assign([], goodsList)
                    addPredetermine(ruleFormList).then((res) => {
                        // console.log(res, '新增场地预定配置');
                        // 预定场地 表单重置
                        this.$refs["reservationRef"].$refs.ruleForm.resetFields();
                        this.$refs["reservationRef"].ruleForm.destinedDate = ''
                        this.$refs["reservationRef"].ruleForm.monthPayCompany = ''
                        this.getTableList()
                        if (res.code == 200) {
                            this.$message({
                                message: "预约成功",
                                type: 'success'
                            });
                            this.dialogVisible = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'warning'
                            });
                            this.dialogVisible = true;
                        }
                    })
                } else {
                    console.log('失败');
                }
            })
        },
        // 打开预定场地弹窗
        handleFix() {
            this.dialogVisible = true
        },
        // 导出
        exportHandler() {
            paymentListExport(this.suggestionObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "场地预定",
                    taskStatus: 0,
                    rootPath: "customerGSPath",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
        // 取消预约 配置
        getRemoveOrder() {
            this.$refs.ruleFormDialog.validate((valid) => {
                if (valid) {
                    removeOrder({
                        id: this.cancelReasonId,
                        cancelReason: this.ruleFormDialog.cancelReason,
                        refund: this.cancelchecked
                    }).then((res) => {
                        // console.log(res, '取消订单');
                        this.dialogVisible1 = false
                        this.ruleFormDialog.cancelReason = ''
                        this.cancelShow = false
                        this.getTableList();
                        if (res.code == 200) {
                            this.$message({
                                message: "取消成功",
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'warning'
                            });
                        }
                    })
                }
            })

        },
        // 搜索框 场地名称下拉列表配置
        // getSearchList() {
        //     getSerachList().then((res) => {
        //         // console.log(res, '11111');
        //         let List = []
        //         res.map((item) => {
        //             List.push({
        //                 label: item.siteName,
        //                 value: item.id
        //             })
        //         })
        //         this.dataset.searchLineConfig.siteListStr.optionList = List
        //     })
        // },
    },
    watch: {
        suggestionObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        },
    }
}
</script>

<style lang="less" scoped>
.stadium-reservation {
    .filter-line {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            height: 36px;
        }

        .icon-loudoutu {
            font-size: 12px;
        }

        .header-filter-button {
            cursor: pointer;
            width: 84px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .filter-button-open {
            color: #409eff;
            background: #ecf5ff;
            border: 1px solid #c6e2ff;
        }

        .filter-button-close {
            color: #2a4158;
            background: #ffffff;
            border: 1px solid #e7e8eb;
        }
    }

    ::v-deep .el-dialog__body {
        padding-top: 0 !important;
        padding-bottom: 20px !important;
    }

    .Dialog {
        .dialog-title {
            font-size: 12px;
            color: gray;
            margin-bottom: 5px;
        }

        .dialog-checkbox {
            color: gray;
            margin-top: 5px;
        }
    }

    // 弹窗头部和底部固定
    .Popup {
        :deep(.el-dialog) {
            height: 100%;

            .el-dialog__body {
                max-height: calc(100% - 154px);
                overflow-y: auto;
            }
        }
    }

}
</style>
<style lang="less">
.selectorTime {
    &.el-select-dropdown.is-multiple .el-select-dropdown__item {
        padding-left: 40px;
        padding-right: 20px;

        &.selected::after {
            left: 20px
        }

        &::before {
            width: 16px;
            height: 16px;
            position: absolute;
            left: 18px;
            top: 8px;
            border-radius: 20%;
            content: "";
            border: 1px solid #c0c4cc;
        }
    }
}

.link-span {
    cursor: pointer;
    color: #409eff;
}
</style>